.App {
  text-align: center;
}

.App  {
border: solid 2px #1b75b9;
border-radius: 10px;
}
.App > form {
  padding: 7px;
  text-align: left;
}
.App form input {
  

}
div.display {
  max-width: 400px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
div.creditcard {
  max-width:300px; 
  padding:20px;
   background-color: #c0cad3;
   border-style: solid;
   border-width: 3px;
   border-radius: 8px;
   border-color: #1b75b9;
   margin: auto;
   margin-bottom: 5px;
}

td.ant-descriptions-item {
  padding-bottom: 0px !important;
}
div.ant-picker-input {
  min-width: 100px;
}